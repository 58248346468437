import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import Loader from '@/components/Icons/Loader'
import GeneralMixin from '@/mixins/general'

import '@/assets/css/tailwind.css'

Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.mixin(GeneralMixin)

Vue.component('Loader', Loader)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
