import axios from '@/plugins/axios'

export default {
  get (id) {
    return axios.get(`campaigns/${id}`)
      .then(response => response.data)
  },

  generateOrder (id, payload) {
    return axios.post(`campaigns/${id}/orders`, payload)
      .then(response => response.data)
  },

  getOrder (campaignID, orderID) {
    return axios.get(`campaigns/${campaignID}/orders/${orderID}`)
      .then(response => response.data)
  },
}
