<template>
  <div>
    <loading-overlay v-if="$store.state.building" />

    <router-view />
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay'

export default {
  name: 'App',

  components: {
    LoadingOverlay,
  },
}
</script>

<style lang="scss">
//
</style>
