import Vue from 'vue'
import Vuex from 'vuex'
import CampaignRepository from '@/repositories/CampaignRepository'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    building: true,
    campaign: Object,
    order: Object,
  },

  mutations: {
    campaign (state, campaign) {
      state.campaign = campaign
    },

    order (state, order) {
      state.order = order
    },

    constructionStarted (state) {
      state.building = true
    },

    constructionFinished (state) {
      state.building = false
    },
  },

  actions: {
    async getCampaign ({ commit }, id) {
      const campaign = await CampaignRepository.get(id)

      commit('campaign', campaign)
    },

    async generateOrder ({ state }, payload) {
      return await CampaignRepository.generateOrder(state.campaign.id, payload)
    },

    async getOrder ({ commit, state }, orderID) {
      const order = await CampaignRepository.getOrder(state.campaign.id, orderID)

      commit('order', order)
    },
  },

  modules: {
  },
})
