<template>
  <component :is="homeTemplate" />
</template>

<script>
export default {
  name: 'HomeFacade',

  data: () => ({
    template: null,
  }),

  beforeRouteEnter (to, from, next) {
    next(async (vm) => {
      const id = to.params.campaign

      await vm.$store.dispatch('getCampaign', id)

      vm.template = vm.$store.state.campaign.template.id.toLowerCase()

      vm.$store.commit('constructionFinished')
    })
  },

  computed: {
    homeTemplate () {
      return this.template
        ? () => import('./templates/' + this.template + '/Home')
        : null
    },
  },
}
</script>
