import dayjs from 'dayjs'

export default {
  computed: {
    campaign () {
      return this.$store.state.campaign
    },

    order () {
      return this.$store.state.order
    },
  },

  methods: {
    currency (value) {
      return value / 100
    },

    tsFormat (ts, format = 'DD-MM-YYYY') {
      return dayjs.unix(ts).format(format)
    },
  },
}
