import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeFacade from '../views/HomeFacade.vue'
import ReceiptFacade from '../views/ReceiptFacade.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:campaign',
    name: 'HomeFacade',
    component: HomeFacade,
  },
  {
    path: '/:campaign/receipt/:order',
    name: 'ReceiptFacade',
    component: ReceiptFacade,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
