<template>
  <component :is="receiptTemplate" />
</template>

<script>
export default {
  name: 'ReceiptFacade',

  data: () => ({
    template: null,
  }),

  beforeRouteEnter (to, from, next) {
    next(async (vm) => {
      vm.$store.commit('constructionStarted')

      const campaignID = to.params.campaign
      const orderID = to.params.order

      await vm.$store.dispatch('getCampaign', campaignID)
      await vm.$store.dispatch('getOrder', orderID)

      vm.template = vm.$store.state.campaign.template.id.toLowerCase()

      vm.$store.commit('constructionFinished')
    })
  },

  computed: {
    receiptTemplate () {
      return this.template
        ? () => import('./templates/' + this.template + '/Receipt')
        : null
    },
  },
}
</script>
